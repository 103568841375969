var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("page-header", { attrs: { title: "패키지 견적 문의하기" } }),
      _c("div", [
        _c("div", { staticClass: "ui-border-line ui-h-0 ui-mb-3" }),
        _c("h4", [_vm._v("필요한 내용")]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.formData.usage,
              expression: "formData.usage"
            }
          ],
          attrs: {
            type: "text",
            placeholder: "예) 회사 체육대회, 워크샵,  세미나 등",
            required: ""
          },
          domProps: { value: _vm.formData.usage },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.formData, "usage", $event.target.value)
            }
          }
        }),
        _c("div", { staticClass: "ui-border-line ui-h-0" })
      ]),
      _c("div", [
        _c("div", { staticClass: "ui-border-line ui-h-0 ui-mb-3" }),
        _c("h4", [_vm._v("날짜 / 시간")]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.formData.runday,
              expression: "formData.runday"
            }
          ],
          attrs: {
            type: "text",
            placeholder: "예) 2019.10.27 오후 1시부터",
            required: ""
          },
          domProps: { value: _vm.formData.runday },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.formData, "runday", $event.target.value)
            }
          }
        }),
        _c("div", { staticClass: "ui-border-line ui-h-0" })
      ]),
      _c("div", [
        _c("div", { staticClass: "ui-border-line ui-h-0 ui-mb-3" }),
        _c("h4", [_vm._v("필요한 지역")]),
        _c("p", { staticClass: "block-subtitle" }, [
          _vm._v("지역과 상호,건물명 등을 함께 적어주세요.")
        ]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.formData.location,
              expression: "formData.location"
            }
          ],
          attrs: {
            type: "text",
            placeholder: "예) 경기도 양평 00체육관",
            required: ""
          },
          domProps: { value: _vm.formData.location },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.formData, "location", $event.target.value)
            }
          }
        }),
        _c("div", { staticClass: "ui-border-line ui-h-0" })
      ]),
      _c("div", [
        _c("div", { staticClass: "ui-border-line ui-h-0 ui-mb-3" }),
        _c("h4", [_vm._v("인원수")]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.formData.personnel,
              expression: "formData.personnel"
            }
          ],
          attrs: { type: "number", min: "1", required: "" },
          domProps: { value: _vm.formData.personnel },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.formData, "personnel", $event.target.value)
            }
          }
        }),
        _c("div", { staticClass: "ui-border-line ui-h-0" })
      ]),
      _c("div", [
        _c("div", { staticClass: "ui-border-line ui-h-0 ui-mb-3" }),
        _c("h4", [_vm._v("요청 및 전달사항")]),
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.formData.contents,
              expression: "formData.contents"
            }
          ],
          ref: "textarea",
          attrs: {
            placeholder: "주의사항, 바라는 점 등 상세할수록 좋아요.",
            minlength: "1",
            required: ""
          },
          domProps: { value: _vm.formData.contents },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.formData, "contents", $event.target.value)
            }
          }
        }),
        _c("div", { staticClass: "ui-border-line ui-h-0" })
      ]),
      _c("div", { staticClass: "ui-border-line ui-h-0 ui-mb-3" }),
      _c("footer-box", {
        attrs: {
          submitText: "문의하기",
          submitCb: function() {
            return _vm.postOffer()
          },
          submitDisabled: !_vm.validate
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }