<template>
  <div class="container">
    <page-header
      :title="`패키지 견적 문의하기`"
    />

    <div>
      <div class="ui-border-line ui-h-0 ui-mb-3"></div>
      <h4>필요한 내용</h4>
      <input type="text" v-model="formData.usage" placeholder="예) 회사 체육대회, 워크샵,  세미나 등" required>
      <div class="ui-border-line ui-h-0"></div>
    </div>

    <div>
      <div class="ui-border-line ui-h-0 ui-mb-3"></div>
      <h4>날짜 / 시간</h4>
      <input type="text" v-model="formData.runday" placeholder="예) 2019.10.27 오후 1시부터" required>
      <div class="ui-border-line ui-h-0"></div>
    </div>

    <div>
      <div class="ui-border-line ui-h-0 ui-mb-3"></div>
      <h4>필요한 지역</h4>
      <p class="block-subtitle">지역과 상호,건물명 등을 함께 적어주세요.</p>
      <input type="text" v-model="formData.location" placeholder="예) 경기도 양평 00체육관" required>
      <div class="ui-border-line ui-h-0"></div>
    </div>

    <div>
      <div class="ui-border-line ui-h-0 ui-mb-3"></div>
      <h4>인원수</h4>
      <input type="number" v-model="formData.personnel" min="1" required>
      <div class="ui-border-line ui-h-0"></div>
    </div>

    <div>
      <div class="ui-border-line ui-h-0 ui-mb-3"></div>
      <h4>요청 및 전달사항</h4>
      <textarea
        ref="textarea"
        v-model="formData.contents"
        placeholder="주의사항, 바라는 점 등 상세할수록 좋아요."
        minlength="1"
        required
      >
      </textarea>
      <div class="ui-border-line ui-h-0"></div>
    </div>

    <div class="ui-border-line ui-h-0 ui-mb-3"></div>

    <footer-box
      submitText="문의하기"
      :submitCb="() => postOffer()"
      :submitDisabled="!validate"
    >
    </footer-box>
  </div>
</template>
ㅊ
<script>
import PageHeader from '@/components/common/PageHeader'
import FooterBox from '@/components/common/FooterBox'

export default {
  components: {
    PageHeader,
    FooterBox,
  },
  data() {
    return {
      formData: {
        genre: null,
        location: null,
        runday: null,
        usage: null,
        personnel: null,
        contents: null,
      },
      artistData: {},
    }
  },
  computed: {
    validate() {
      let validate = true
      for (let key in this.formData) {
        if (!this.formData[key]) {
          validate = false
          break
        }
      }
      return validate
    },
  },
  beforeMount() {
    const { artistidx } = this.$route.params

    let artistData = sessionStorage.getItem(`artistData:${artistidx}`)

    if (!artistidx || !artistData) {
      this.$router.back()
      return
    }

    this.artistData = JSON.parse(artistData)
    this.formData.genre = this.artistData.genre
    // this.formData.location = this.artistData.location
  },
  methods: {
    postOffer() {
      if (!this.validate) return

      const req = {
        method: 'post',
        url: `/client/${this.$route.params.artistidx}/cusOrder`,
        data: this.formData,
      }

      this.$http(req)
        .then(({ data }) => {
          log(...Object.values(req), data)

          const resultData = this.$lib.resultCheck(data.resultData)

          if (resultData.success) {
            this.$toast('문의가 전달되었습니다').push('/home')
          } else {
            this.$alert(resultData)
          }
        })
        .catch(log.error)
    },
  }
}
</script>

<style lang="scss" scoped>
h4 {
  margin: 0 !important;
  font-weight: 500 !important;
  font-size: 2.6rem !important;
  line-height: 6.6rem !important;
}

input, textarea {
  padding: 1em;
  width: 100%;
  border: 0;
  font-size: 2rem;
  line-height: 1.5;
  height: 7rem;
  border: 1px solid #979797;
  border-radius: 5px;
}

textarea {
  min-height: 14rem;
}

p {
  color: #979797;
  font-size: 2rem;

  &.block-subtitle {
    margin: -1rem 0 2rem;
    font-size: 1.8rem;
  }
}
</style>